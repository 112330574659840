<template>
    <div class="order">
        <p class="deliver_title">
            <span>
                <i
                    class="iconfont icon-dilanxianxingiconyihuifu_huabanfuben"
                ></i
                >交付订单</span
            >
        </p>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="planData"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="下单时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.placeOrderTime.slice(0, 10) || '- -'
                    }}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="交付订单编号"
                prop="reportDomain"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.orderPlanNo || '- -' }}</span>
                </template>
            </el-table-column>

            <el-table-column label="客户名称" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.customerName || '- -' }}</span>
                </template>
            </el-table-column>

            <el-table-column label="地区" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.province }},{{ scope.row.city }}</span>
                </template>
            </el-table-column>
            <el-table-column label="类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ $MailStatus.planType(scope.row.planType) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1 ? '等待资料审核' : '已成功'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="账户数" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.number || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="额度" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.amount || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="年限" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.term || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下单平台" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.libraryName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="支付时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.placeOrderTime.slice(0, 10) || '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="销售订单" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div class="sale_order">
                        <div>
                            {{ scope.row.orderNo || '- -' }} <br />
                            {{
                                scope.row.orderStatus == 1
                                    ? '未交付'
                                    : scope.row.orderStatus == 2
                                    ? '部分交付'
                                    : scope.row.orderStatus == 3
                                    ? '已交付'
                                    : '- -'
                            }}
                        </div>
                    </div>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { orderPlanList } from '@/api/order/order.js';
import { reportUpdate } from '@/api/report/newReport';
import { reportSetting } from '@/api/report/report';
export default {
    data() {
        return {
            trialData: [],
            planData: [],
            trialOrderUpdate: false,
            isShow: false,
        };
    },
    methods: {
        getData(data) {
            console.log(data);
            this.trialData = [data];
            this.getPlanList(data);
            this.trialOrderUpdate = JSON.parse(
                sessionStorage.getItem('reportInfoUpdate')
            );
            if (
                data.trialStatus == 1 ||
                data.trialStatus == 7 ||
                data.trialStatus == 8 ||
                data.trialStatus == 9
            ) {
                this.isShow = false;
            } else {
                this.isShow = true;
            }
        },
        getPlanList(data) {
            let obj = {
                param: {
                    chanceNo: data.chanceNo,
                },
            };
            orderPlanList(obj).then((res) => {
                if (res.code == 200) {
                    this.planData = res.data.list;
                }
            });
        },
        onUpData(field, fieldData) {
            console.log(field);
            let data = {
                param: {
                    id: this.$route.query.id,
                    [field]: fieldData,
                },
            };

            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.order {
    width: 100%;
    .trial_title {
        padding: 16px;
        span {
            font-size: 14px;
            color: #333333;
            font-weight: 600;

            i {
                font-size: 14px;
                color: #4176fc;
                margin-right: 8px;
            }
        }
    }
    .deliver_title {
        padding: 16px;
        font-weight: 600;
        font-weight: 600;
        span {
            font-size: 14px;
            color: #333333;

            i {
                font-size: 14px;
                color: #f5a623;
                margin-right: 8px;
            }
        }
    }
}
</style>
