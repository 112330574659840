<template>
    <div class="tmDetail">
        <div class="info">
            <p class="info_title">
                <span>
                    <i class="iconfont icon-icon_yingyongguanli"></i
                    >基础信息</span
                >
                <el-button type="primary" size="mini" plain @click="onClose()">
                    返回列表</el-button
                >
            </p>
            <div class="info_box">
                <div>
                    <p>
                        <span>报备名称</span>
                        <span>{{ data.reportName }}</span>
                    </p>
                    <p>
                        <span>创建时间</span>
                        <span>{{
                            data.createTime
                                ? data.createTime.slice(0, 10)
                                : '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>商机ID</span>
                        <span>{{ data.chanceNo || '- -' }}</span>
                    </p>
                </div>
                <div>
                    <p>
                        <span>域名</span>
                        <span>{{ data.reportDomain }}</span>
                    </p>
                    <p>
                        <span>报备品牌</span>
                        <span>{{ '腾讯企业邮箱' }}</span>
                    </p>
                    <p>
                        <span>创建人</span>
                        <span>{{ data.createAdminName }} </span>
                    </p>
                </div>

                <div>
                    <p>
                        <span>所在地</span>
                        <span v-if="data.areaType == 1">{{
                            $address.address(data.provinceId, data.cityId)
                        }}</span>
                        <span v-else>{{ data.abroadArea }}</span>
                    </p>
                    <p>
                        <span>所属人</span>
                        <span>{{ data.adminName }}</span>
                    </p>
                    <p>
                        <span>合作人</span>
                        <el-tooltip
                            v-if="
                                data.chanceCollaboratorBOList &&
                                data.chanceCollaboratorBOList.length > 0
                            "
                            class="item"
                            effect="dark"
                            content="Top Center 提示文字"
                            placement="top"
                        >
                            <div slot="content">
                                <p
                                    style="line-height: 1.8"
                                    v-for="item in data.chanceCollaboratorBOList"
                                    :key="item.id"
                                >
                                    {{ item.collaboratorAdminName }}
                                </p>
                            </div>
                            <span>{{
                                data.chanceCollaboratorBOList[0]
                                    .collaboratorAdminName
                            }}</span>
                        </el-tooltip>
                        <span
                            v-if="
                                data.chanceCollaboratorBOList &&
                                data.chanceCollaboratorBOList.length > 0
                            "
                            class="circle_toast"
                        >
                            {{ data.chanceCollaboratorBOList.length }}
                        </span>
                        <span v-else>- -</span>
                        <el-button
                            v-if="collaborator && data.reportStatus == 3"
                            type="primary"
                            size="mini"
                            plain
                            @click="onUpdate()"
                        >
                            编辑</el-button
                        >
                    </p>
                </div>
            </div>
        </div>
        <div class="info" style="margin-top: 12px">
            <div class="info_title">
                <span>
                    <i
                        class="iconfont icon-gongzuotai"
                        style="color: #4176fc"
                    ></i
                    >关联客户</span
                >
            </div>
            <div class="info_box">
                <div v-for="item in data.chanceCustomerBOList" :key="item.id">
                    <p>
                        <span>客户名称</span>
                        <span>{{ item.companyName }}</span>
                    </p>
                    <p>
                        <span style="width: 125px">统一社会信用代码</span>
                        <span>{{ data.creditNo ? data.creditNo : '- -' }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="report">
            <p class="report_title">
                <span> <i class="iconfont icon-gongzuotai"></i>报备信息</span>
            </p>
            <div class="report_box">
                <p>
                    <span>状态</span>
                    <span>
                        <em class="status">
                            {{ $MailStatus.reportStatus(data.reportStatus) }}
                        </em>
                    </span>
                </p>
                <p>
                    <span>报备时间</span>
                    <span>{{
                        data.reportApplyTime
                            ? data.reportApplyTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span>回收时间</span>
                    <span>
                        {{
                            reportExpireTime
                                ? reportExpireTime.slice(0, 10)
                                : '- -'
                        }}
                    </span>
                </p>
                <p>
                    <span>报备库</span>
                    <span> 伽搜 </span>
                </p>
            </div>
        </div>
        <div class="business">
            <p class="business_title">
                <span>
                    <i class="iconfont icon-chuchangbianhao"></i>商机信息</span
                >
            </p>
            <div class="business_box">
                <div>
                    <p>
                        <span>客户</span>
                        <span
                            @click="onDetails"
                            style="color: #2370eb; cursor: pointer"
                            >{{ data.companyName }}</span
                        >
                    </p>

                    <p>
                        <span>联系人</span>
                        <span>
                            <el-select
                                :disabled="!businessInfoUpdate"
                                size="small"
                                style="width: 180px"
                                placeholder="请选择搜索或直接输入"
                                clearable
                                allow-create
                                filterable
                                remote
                                v-model="contactData"
                                value-key="contactName"
                                @change="getContactPhone()"
                            >
                                <el-option
                                    v-for="item in contactList"
                                    :key="item.index"
                                    :label="item.contactName"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </span>
                    </p>
                    <p>
                        <span>联系电话</span>
                        <span>
                            <el-select
                                :disabled="!businessInfoUpdate"
                                size="small"
                                style="width: 180px"
                                placeholder="请选择搜索或直接输入"
                                clearable
                                allow-create
                                filterable
                                remote
                                v-model="contactPhoneData"
                                value-key="contactContent"
                                @change="getPhoneData()"
                            >
                                <el-option
                                    v-for="item in phoneList"
                                    :key="item.index"
                                    :label="item.contactContent"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </span>
                    </p>
                </div>
                <div>
                    <p>
                        <span>用户数</span>
                        <span>
                            <el-select
                                :disabled="!businessInfoUpdate"
                                v-model="estimateNumber"
                                size="small"
                                style="width: 180px"
                                clearable
                                placeholder="请选择"
                                @change="
                                    onUpData('estimateNumber', estimateNumber)
                                "
                            >
                                <el-option
                                    v-for="item in $MailStatus.estimateNumber()"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </span>
                    </p>
                    <p>
                        <span>年限</span>
                        <span>
                            <el-select
                                :disabled="!businessInfoUpdate"
                                v-model="estimateTerm"
                                size="small"
                                style="width: 180px"
                                clearable
                                placeholder="请选择"
                                @change="onUpData('estimateTerm', estimateTerm)"
                            >
                                <el-option
                                    v-for="item in $MailStatus.estimateTerm()"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </span>
                    </p>
                    <p>
                        <span>预计金额</span>
                        <span>
                            <el-input
                                :disabled="!businessInfoUpdate"
                                v-model="estimateAmount"
                                size="small"
                                placeholder="请输入内容"
                                style="width: 180px"
                                @change="
                                    onUpData('estimateAmount', estimateAmount)
                                "
                            ></el-input>
                        </span>
                    </p>
                </div>
                <div>
                    <p>
                        <span>合作时间</span>
                        <span>
                            <el-date-picker
                                :disabled="!businessInfoUpdate"
                                v-model="estimateCooperateTime"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                size="small"
                                style="width: 180px"
                                @change="
                                    onUpData(
                                        'estimateCooperateTime',
                                        estimateCooperateTime
                                    )
                                "
                            >
                            </el-date-picker>
                        </span>
                    </p>
                    <p>
                        <span>报备理由</span>
                        <span>
                            <el-input
                                :disabled="!businessInfoUpdate"
                                type="textarea"
                                style="width: 210px"
                                :rows="3"
                                placeholder="请输入内容"
                                v-model="reportReason"
                                @change="onUpData('reportReason', reportReason)"
                            >
                            </el-input>
                        </span>
                    </p>
                </div>
                <div>
                    <p style="position: relative">
                        <span>备注</span>
                        <el-input
                            :disabled="!businessInfoUpdate"
                            type="textarea"
                            style="width: 180px; margin-right: 30px"
                            :rows="3"
                            placeholder="请输入内容"
                            v-model="reportRemark"
                            @change="onUpData('reportRemark', reportRemark)"
                        >
                        </el-input>
                    </p>
                </div>
            </div>
        </div>
        <div class="record">
            <div class="tab">
                <p
                    :class="isColor == 1 ? 'isColor' : ''"
                    @click="onIndex(1)"
                    v-if="isShow"
                >
                    订单信息
                    <!-- <span></span> -->
                </p>
                <p :class="isColor == 2 ? 'isColor' : ''" @click="onIndex(2)">
                    申请与处理记录
                    <!-- <span></span> -->
                </p>
                <p :class="isColor == 3 ? 'isColor' : ''" @click="onIndex(3)">
                    内容操作日志
                    <!-- <span></span> -->
                </p>
            </div>
            <div v-show="isColor == 1" class="order"><Order ref="order" /></div>
            <div v-show="isColor == 2"><Approval ref="approval" /></div>
            <div v-show="isColor == 3"><Journal ref="journal" /></div>
        </div>
        <!-- 添加协作人 -->
        <el-dialog
            :visible.sync="dialogCooperation"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    合作人调整
                </div>
            </template>
            <div>
                <AddCooperation ref="addcooperation" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Order from './order.vue';
import Approval from './approval.vue';
import Journal from './journal.vue';
import { reportGet, reportUpdate } from '@/api/report/newReport';
import { contactList } from '@/api/user/ae/customer';
import { contactAdd } from '@/api/user/ae/customer';
import { orderPlanList } from '@/api/order/order.js';
import { getCustomerId } from '@/api/user/ae/customer';
import AddCooperation from './addCooperation.vue';
export default {
    components: {
        Order,
        Approval,
        Journal,
        AddCooperation,
    },
    data() {
        return {
            isColor: 1,
            data: {},
            tmFilingList: [],
            contactList: [],
            phoneList: [],
            libraryCategoryVal: '',
            reportExpireTime: '',
            estimateNumber: '',
            estimateTerm: '',
            reportRemark: '',
            dialogCooperation: false,
            reportReason: '',
            estimateAmount: '',
            estimateCooperateTime: '',
            remark: '',
            contactData: {},
            contactPhoneData: {},
            isAddContact: false,
            btnP: {},
            reportInfoUpdate: false,
            businessInfoUpdate: false,
            isShow: false,
            planList: [],
            collaborator: false,
        };
    },
    mounted() {
        this.getAccount();
        this.reportInfoUpdate = JSON.parse(
            sessionStorage.getItem('reportInfoUpdate')
        );
    },
    methods: {
        getAccount() {
            this.collaborator = JSON.parse(this.$route.query.collaborator);
            // let obj = {
            //     param: {},
            //     pageNum: 0,
            //     pageSize: 0,
            // };
            // accountList(obj).then((res) => {
            //     this.tmFilingList = res.data.list;
            this.getData();
            // });
        },
        returnDis(val) {
            if (val) {
                let obj = this.tmFilingList.find((item) => {
                    return item.id == val;
                });

                if (
                    Number(obj.maxReportNumber) -
                        Number(obj.consumedNumber) -
                        Number(obj.channelConsumedNumber) <=
                    0
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        onIndex(i) {
            this.isColor = i;
            if (i == 1) {
                setTimeout(() => {
                    this.$refs.order.getData(this.data, this.btnP);
                });
            }
            if (i == 2) {
                setTimeout(() => {
                    this.$refs.approval.getData();
                });
            }
            if (i == 3) {
                setTimeout(() => {
                    this.$refs.journal.getData();
                });
            }
        },
        getData() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            reportGet(data).then((res) => {
                console.log(res.data);

                if (
                    res.data.adminId == sessionStorage.getItem('adminId') &&
                    JSON.parse(sessionStorage.getItem('businessInfoUpdate'))
                ) {
                    this.businessInfoUpdate = true;
                }
                this.data = res.data;
                this.libraryCategoryVal = res.data.libraryCategory || '';
                this.reportExpireTime = res.data.reportExpireTime || '';
                this.contactName = res.data.contactName || '';

                this.estimateNumber = res.data.estimateNumber || '';
                this.estimateTerm = res.data.estimateTerm || '';
                this.reportRemark = res.data.reportRemark || '';
                this.estimateAmount = res.data.estimateAmount || '';
                this.reportReason = res.data.reportReason || '';
                this.estimateCooperateTime =
                    res.data.estimateCooperateTime || '';
                let obj = {
                    param: {
                        nicheNo: res.data.nicheNo,
                    },
                };
                orderPlanList(obj).then((r) => {
                    if (res.code == 200) {
                        this.planData = r.data.list;
                        if (
                            (this.data.trialStatus == 1 ||
                                this.data.trialStatus == 7 ||
                                this.data.trialStatus == 8 ||
                                this.data.trialStatus == 9) &&
                            this.planList.length == 0
                        ) {
                            this.isShow = false;
                            this.onIndex(2);
                        } else {
                            this.isShow = true;
                            this.onIndex(1);
                        }
                    }
                });

                this.getContactList();
                if (
                    this.contactList.find(
                        (v) => v.contactName == this.data.contactName
                    ) !== undefined
                ) {
                    this.contactList.forEach((item) => {
                        if (item.contactName == this.data.contactName) {
                            this.phoneList = item.customerContactWayDOList;
                            item.customerContactWayDOList.forEach((itm) => {
                                if (
                                    itm.contactContent ==
                                        this.data.contactPhone &&
                                    itm.contactWay == 1
                                ) {
                                    this.contactData = item.contactName;
                                    this.contactPhoneData = itm.contactContent;
                                }
                            });
                        } else {
                            this.contactData = this.data.contactName;
                            this.contactPhoneData = this.data.contactPhone;
                            return;
                        }
                    });
                } else {
                    this.contactData = this.data.contactName;
                    this.contactPhoneData = this.data.contactPhone;
                    this.isAddContact = true;
                }
            });
        },
        getContactList() {
            let data = {
                param: {
                    customerId: this.data.customerId,
                },
            };
            contactList(data).then((res) => {
                console.log(res.data);
                this.contactList = res.data.list;
            });
        },
        // 获取联系人电话
        getContactPhone(row) {
            if (!this.contactData.id) {
                this.isAddContact = true;
            }
            this.contactPhoneData = '';
            this.phoneList = [];
            for (let i in this.contactData.customerContactWayDOList) {
                if (
                    this.contactData.customerContactWayDOList[i].contactWay == 1
                ) {
                    this.phoneList.push(
                        this.contactData.customerContactWayDOList[i]
                    );
                }
            }
        },
        getPhoneData() {
            if (!this.contactPhoneData.contactId) {
                this.isAddContact = true;
            }
            if (!this.contactData) {
                return this.$message.error('请选择联系人');
            }
            if (!this.contactPhoneData) {
                return this.$message.error('请选择联系电话');
            }
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            console.log(typeof this.contactData);
            if (typeof this.contactData == 'object') {
                data.param.contactName = this.contactData.contactName;
            }
            if (typeof this.contactPhoneData == 'object') {
                data.param.contactPhone = this.contactPhoneData.contactContent;
            }
            if (typeof this.contactData == 'string') {
                data.param.contactName = this.contactData;
            }
            if (typeof this.contactPhoneData == 'string') {
                data.param.contactPhone = this.contactPhoneData;
            }
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.getContactList();
                }
            });
            if (this.isAddContact) {
                let obj = {
                    param: {
                        customerContactDO: {
                            companyName: this.data.companyName,
                            contactName: this.contactData.contactName
                                ? this.contactData.contactName
                                : this.contactData,
                            customerContactWayDOList: [
                                {
                                    contactContent: this.contactPhoneData
                                        .contactContent
                                        ? this.contactPhoneData.contactContent
                                        : this.contactPhoneData,
                                    contactWay: 1,
                                },
                            ],
                        },
                    },
                };
                obj.param.customerContactDO.customerId = this.data.customerId;
                obj.param.customerContactDO.followAdminId =
                    sessionStorage.getItem('adminId');
                contactAdd(obj).then((res) => {
                    this.getContactList();
                });
            }
        },
        onUpData(field, fieldData) {
            console.log(field);
            let data = {
                param: {
                    id: this.$route.query.id,
                    [field]: fieldData,
                },
            };

            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                }
            });
        },
        onDetails() {
            let data = {
                param: {
                    id: this.data.customerId,
                },
            };
            getCustomerId(data).then((res) => {
                let btn = JSON.parse(sessionStorage.getItem('isButArr'));
                let type = false;
                for (let i in btn) {
                    if (btn[i].route == 'upData') {
                        type = true;
                        break;
                    }
                }
                if (type) {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            type: type,
                            isTab: 1,
                        },
                    });
                } else {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            isTab: 1,
                        },
                    });
                }
                this.$formDetails.commit(
                    'detailsData',
                    JSON.stringify(res.data)
                );
                this.$choiceLabel.commit('getType', '2');
            });
        },
        onClose() {
            this.$router.back(-1);
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        onUpdate() {
            this.dialogCooperation = true;
            setTimeout(() => {
                this.$refs.addcooperation.getData(this.data);
            }, 0);
        },
        handleClose() {
            this.dialogCooperation = false;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
.tmDetail {
    width: 100%;
    .info {
        background: #fff;
        border-radius: 4px;
        width: 100%;
        .info_title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            span {
                font-size: 14px;
                color: #333333;
                font-weight: 600;

                i {
                    font-size: 14px;
                    color: #f7b84f;
                    margin-right: 8px;
                }
            }
            button {
                background: #fff;
                color: #2370eb;
                border-color: #2370eb;
            }
            .add {
                background-color: #2370eb;
                color: #fff;
            }
            .info_btn {
                color: #666;
                border-color: #e5e5e5;
            }
        }
        .info_box {
            display: flex;
            padding-left: 36px;
            div {
                flex: 1;
                font-size: 14px;
                p {
                    padding-bottom: 16px;
                }
                span:nth-child(1) {
                    color: #666666;
                    display: inline-block;
                    width: 70px;
                }
                span:nth-child(2) {
                    color: #333333;
                    font-weight: 600;
                }
            }
            button {
                background: #fff;
                color: #333;
                border-color: #feb516;
                border-radius: 30px;
                margin-left: 15px;
                padding: 6px 15px;
            }
            // div:nth-child(1) {
            //     flex: 1.3;
            // }
        }
    }
    .report {
        background: #fff;
        border-radius: 4px;
        width: 100%;
        margin-top: 12px;
        .report_title {
            display: flex;
            align-items: center;
            padding: 16px;
            span {
                font-size: 14px;
                color: #333333;
                font-weight: 600;

                i {
                    font-size: 14px;
                    color: #4176fc;
                    margin-right: 8px;
                }
            }
        }
        .report_box {
            display: flex;
            padding-left: 36px;
            padding-bottom: 16px;
            p {
                flex: 1;
                font-size: 14px;
                display: flex;
                align-items: center;
                span:nth-child(1) {
                    color: #666666;
                    display: inline-block;
                    width: 70px;
                }
                span:nth-child(2) {
                    color: #333333;
                    font-weight: 600;
                }
            }
            /deep/ .el-input__inner {
                font-weight: 600 !important;
                color: #333;
                font-size: 14px;
            }
        }
    }
    .business {
        background: #fff;
        border-radius: 4px;
        width: 100%;
        margin-top: 12px;
        .business_title {
            display: flex;
            align-items: center;
            padding: 16px;
            span {
                font-size: 14px;
                color: #333333;
                font-weight: 600;
                i {
                    font-size: 14px;
                    color: #4176fc;
                    margin-right: 8px;
                }
            }
        }
        .business_box {
            display: flex;
            padding-left: 36px;
            div {
                flex: 1;
                font-size: 14px;
                p {
                    padding-bottom: 16px;
                    line-height: 35px;
                }
                span:nth-child(1) {
                    color: #666666;
                    display: inline-block;
                    width: 70px;
                }
                span:nth-child(2) {
                    color: #333333;
                    font-weight: 600;
                }
            }
            div:nth-last-child(2) {
                flex: 1;
                p:nth-child(2) {
                    display: flex;
                    align-items: flex-start;
                }
            }
            div:last-child {
                flex: 1;
                p:nth-child(1) {
                    display: flex;
                    align-items: flex-start;
                }
            }
            /deep/ .el-input__inner {
                font-weight: 600 !important;
                color: #333;
                font-size: 14px;
            }
            /deep/ .el-textarea__inner {
                font-weight: 600 !important;
                color: #333;
                font-size: 14px;
            }
        }
    }
    .record {
        background: #fff;
        border-radius: 4px;
        width: 100%;
        margin-top: 12px;
        .tab {
            border-bottom: 1px solid #eeeeee;
            display: flex;
            padding-left: 36px;
            p {
                padding: 16px 48px 16px 0;
                font-size: 14px;
                color: #333333;
                cursor: pointer;
                position: relative;
                span {
                    display: none;
                    width: 16px;
                    height: 2px;
                    background: #2370eb;
                    position: absolute;
                    bottom: 0;
                }
            }
            .isColor {
                color: #2370eb;
                span {
                    display: block;
                    width: 16px;
                    height: 2px;
                    background: #2370eb;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                }
            }
        }
    }
}
.status {
    padding: 0px 10px;
    background: rgba(35, 112, 235, 0.09);
    border-radius: 10px;
    border: 1px solid #2370eb;
    font-style: normal;
}
.circle_toast {
    background-color: #2370eb;
    color: #fff;
    display: inline-block;
    padding: 3px 5px;
    line-height: 1;
    border: 1px solid #2370eb;
    border-radius: 20px;
    margin-left: 15px;
}
</style>
