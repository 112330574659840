<template>
    <div class="approval">
        <!-- <p class="approval_title">
            <span> <i class="iconfont icon-chuchangbianhao"></i> 内容操作日志</span>
        </p> -->
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="350px"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="操作时间" width="180" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.createTime || '- -' }}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="操作人员"
                prop="reportDomain"
                width="180"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.adminName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作内容" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.content || '- -' }}</span>
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { reportHistory } from '@/api/report/newReport';
export default {
    data() {
        return {
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
        };
    },
    methods: {
        getData() {
            let data = {
                param: {
                    chanceId: this.$route.query.id,
                    type: 2,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            reportHistory(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
.approval {
    width: 100%;
    .approval_title {
        padding: 16px;
        span {
            font-size: 14px;
            color: #333333;

            i {
                font-size: 14px;
                color: #4176fc;
                margin-right: 8px;
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
